import { FC, useCallback, useMemo } from 'react';
import { FilterProps } from './FilterProps';
import { useQuery } from '@apollo/client';
import { graphql } from 'gql.tada';
import { useTableView } from '../../../contexts/table-view/TableViewContext';
import LanguageUtils from '../../../utils/LanguageUtils';
import Checkbox from '../../shared/form-control/Checkbox';
import { mouseAndKeyboardCallbackProps } from '../../../utils/ComponentUtils';
import { useTranslation } from 'react-i18next';
import { useTableViewFilters } from '../../../contexts/table-view/TableViewFilterContext';
import { TemplateFilterValue } from '../../../models/TableViewFilters';
import { Option } from '../../Option';

export const ModuleFormTemplatesFilterQuery = graphql(`
  query ModuleTemplates($cmId: UUID!, $tmSectionId: UUID) {
    templateFormsByClientModule(first: 100, clientModuleId: $cmId, templateModuleSectionId: $tmSectionId) {
      nodes {
        id
        translations
      }
    }
  }
`);

const TemplateFormFilter: FC<FilterProps> = ({ columnConfig, selectedTemplateId }) => {
  const { clientModuleId, templateModuleSectionId } = useTableView();
  const { t } = useTranslation(['table-view', 'common', 'distribution']);
  const { filters, setFilters } = useTableViewFilters();
  const filterValue = useMemo(
    () => filters?.[selectedTemplateId]?.[columnConfig.value]?.filter as TemplateFilterValue | undefined,
    [columnConfig.value, filters, selectedTemplateId],
  );
  const { loading: fetchingTemplateforms, data: templateFormData } = useQuery(ModuleFormTemplatesFilterQuery, {
    variables: {
      cmId: clientModuleId,
      tmSectionId: templateModuleSectionId || undefined,
    },
  });
  const templateOptions = useMemo(() => {
    if (fetchingTemplateforms) return [];
    const data = templateFormData?.templateFormsByClientModule?.nodes ?? [];
    return data.map((template) => ({
      id: `${template?.id}`,
      text: LanguageUtils.getTranslation('title', template?.translations || {}),
      value: false,
    }));
  }, [fetchingTemplateforms, templateFormData?.templateFormsByClientModule?.nodes]);

  const toggleSelectAll = useCallback(
    (selectAll: boolean) => {
      const updatedFilter: TemplateFilterValue = selectAll ? templateOptions.map((option) => option.id) : [];
      setFilters(columnConfig, selectedTemplateId, updatedFilter.length > 0 ? updatedFilter : undefined);
    },
    [templateOptions, setFilters, columnConfig, selectedTemplateId],
  );

  const updateFilter = (selectedTemplate: Option<string, boolean>) => {
    const updatedFilter = filterValue?.includes(selectedTemplate.id)
      ? filterValue.filter((id) => id !== selectedTemplate.id)
      : [...(filterValue || []), selectedTemplate.id];

    setFilters(columnConfig, selectedTemplateId, updatedFilter.length > 0 ? updatedFilter : undefined);
  };

  const isSelected = (optionId: string) => filterValue?.includes(optionId) ?? false;

  return (
    <div>
      <div className="text-dpm-14 my-1 flex flex-row justify-between font-medium">
        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(true))} data-cy="select-all">
          {t('common:list.filter.select-all')}
        </span>

        <span className="cursor-pointer hover:underline" {...mouseAndKeyboardCallbackProps(() => toggleSelectAll(false))} data-cy="clear-all">
          {t('common:list.filter.clear-all')}
        </span>
      </div>

      <div className="-mr-4 -mt-2 h-64 w-72 space-y-4 overflow-y-auto">
        {templateOptions.map((template) => {
          return (
            <Checkbox
              key={template.id}
              value={isSelected(template.id)}
              label={template.text}
              onChange={() => updateFilter(template)}
              role="menuitem"
            />
          );
        })}
      </div>
    </div>
  );
};

export default TemplateFormFilter;
